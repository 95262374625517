@import "page-loader-box.css";
@import "visually-hidden.css";
@import "input-search-decoration.css";
@import "thulium.css";
@import "carousel.css";
@import "calendar.css";
@import "capitalize.css";
@import "react-toastify.css";

.break-word {
  overflow-wrap: break-word;
}

.break-spaces {
  white-space: break-spaces;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
}