.calendar-container.react-calendar {
  font-family: 'Ping 4',Arial,sans-serif;
  border: none;
  width: 100%;
  max-width: 600px;
}

.calendar-container .react-calendar__tile--range,
.calendar-container .react-calendar__navigation__label,
.calendar-container .react-calendar__month-view__days__day {
  font-size: 16px;
}

.calendar-container .react-calendar__navigation__label,
.calendar-container .react-calendar__tile--range {
  font-weight: 700;
}

.calendar-container .react-calendar__tile--range {
  background: #CBF1E0;
  color: #3C3F3D;
  font-size: 16px;
}

.calendar-container .react-calendar__tile--rangeStart {
  border-radius: 40% 0 0 40%;
}

.calendar-container .react-calendar__tile--rangeEnd {
  border-radius: 0 40% 40% 0;
}

.calendar-container .react-calendar__tile {
  margin-bottom: 4px;
}

.calendar-container .react-calendar__tile:enabled:hover, 
.calendar-container .react-calendar__tile:enabled:focus,
.calendar-container .react-calendar__navigation button:enabled:hover, 
.calendar-container .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.calendar-container .react-calendar__tile--range:enabled:hover, .calendar-container .react-calendar__tile--range:enabled:focus {
  background: #CBF1E0;
}

.calendar-container .react-calendar__month-view__days__day--weekend {
  color: #3C3F3D;
}

.calendar-container .react-calendar__navigation__label__labelText {
  text-transform: capitalize;
}

.calendar-container .react-calendar__month-view__weekdays__weekday {
  color: #A8A8A8;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
}

.calendar-container .react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}

.calendar-container .react-calendar__month-view__days__day--neighboringMonth {
  color: #C4C4C4;
}

.react-calendar__tile--now.react-calendar__tile--now {
  background: transparent;
  position: relative;
}

.react-calendar__tile--now:after {
  position: absolute;
  font-size: 14px;
  left: 50%;
  top: 80%;
  transform: translate(-50%,-50%);
  content: "•";
}


/*fix problems with scroll on dialogs*/

body {
  overflow: visible!important;
  padding-right: 0!important;
}

.MuiAppBar-root {
  padding-right: 0!important
}
