@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast-container--bottom-right.Toastify__toast-container--bottom-right {
    right: 14em;
}

.Toastify__toast.Toastify__toast {
    padding: 0;
    background: transparent;
    box-shadow: none;
    width: 475px;
}

.Toastify__toast-icon.Toastify__toast-icon {
    display: none;
}

.Toastify__close-button {
    display: none;
}

.Toastify__toast-body.Toastify__toast-body {
    margin: 0;
    padding: 0;
    align-items: normal;
}

@media only screen and (max-width: 960px) {
    .Toastify__toast-container--bottom-right.Toastify__toast-container--bottom-right {
        bottom: 7.5em;
    }

}

@media only screen and (max-width: 600px) {
    .Toastify__toast-container.Toastify__toast-container {
        width: 100%;
    }
    .Toastify__toast.Toastify__toast {
        width: 100%;
    }
    .Toastify__toast-container--bottom-right.Toastify__toast-container--bottom-right.Toastify__toast-container--bottom-right {
        right: 0;
        bottom: 9em;
    }
}
