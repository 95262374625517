.stationary-form {
    font-family: Arial, sans-serif;
    color: #052E6E;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.stationary-form header {
    background-color: #052E6E;
    color: white;
    text-align: center;
    padding: 10px;
}

.stationary-form .container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #052E6E;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
}

.stationary-form form {
    display: flex;
    flex-direction: column;
}

.stationary-form label {
    margin-bottom: 8px;
}

.stationary-form input {
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.stationary-form button {
    background-color: white;
    color: #052E6E;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stationary-form button:hover {
    background-color: #e0e0e0;
}

.stationary-form button:disabled {
    background-color: rgba(224, 224, 224, 0.27);
}
