@font-face {
    font-family: "AllianzNeo";
    src: url("../font/AllianzNeo-Bold.eot");
    src: url("../font/AllianzNeo-Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/AllianzNeo-Bold.woff2") format("woff2"),
    url("../font/AllianzNeo-Bold.woff") format("woff"),
    url("../font/AllianzNeo-Bold.ttf") format("truetype"),
    url("../font/AllianzNeo-Bold.svg#AllianzNeo-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AllianzNeo";
    src: url("../font/AllianzNeo-Light.eot");
    src: url("../font/AllianzNeo-Light.eot?#iefix") format("embedded-opentype"),
    url("../font/AllianzNeo-Light.woff2") format("woff2"),
    url("../font/AllianzNeo-Light.woff") format("woff"),
    url("../font/AllianzNeo-Light.ttf") format("truetype"),
    url("../font/AllianzNeo-Light.svg#AllianzNeo-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AllianzNeo";
    src: url("../font/AllianzNeo-Regular.eot");
    src: url("../font/AllianzNeo-Regular.eot?#iefix") format("embedded-opentype"),
    url("../font/AllianzNeo-Regular.woff2") format("woff2"),
    url("../font/AllianzNeo-Regular.woff") format("woff"),
    url("../font/AllianzNeo-Regular.ttf") format("truetype"),
    url("../font/AllianzNeo-Regular.svg#AllianzNeo-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AllianzNeo";
    src: url("../font/AllianzNeoW01-SemiBold.eot");
    src: url("../font/AllianzNeoW01-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../font/AllianzNeoW01-SemiBold.woff2") format("woff2"),
    url("../font/AllianzNeoW01-SemiBold.woff") format("woff"),
    url("../font/AllianzNeoW01-SemiBold.ttf") format("truetype"),
    url("../font/AllianzNeoW01-SemiBold.svg#AllianzNeoW01-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}