.page-loader-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 960px) {
    /*theme.breakpoints.values.md*/
    .page-loader-box {
        min-height: calc(100vh - 112px); /* minus top and bottom nav height */
    }
}
