.react-multi-carousel-list {
  flex-direction: column!important;
  align-items: flex-start!important;
  padding-bottom: 40px;
}

.react-multi-carousel-item[aria-hidden="true"] {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transform: scale(0.8) translate(-15%, 0);
}

.react-multi-carousel-item--active .react-multi-carousel-item[aria-hidden="true"] {
  visibility: hidden;
  opacity: 1;
}

.react-multi-carousel-item--active + .react-multi-carousel-item[aria-hidden="true"] {
  right: 0;
  opacity: .5;
  visibility: visible;
}

.custom-carousel-dot-list .react-multi-carousel-dot > button {
  border: none;
  background: #C4C4C4;
}

.custom-carousel-dot-list .react-multi-carousel-dot--active > button {
  border: none;
  background: #20A869;
}

.custom-carousel-dot-list.react-multi-carousel-dot-list {
  width: 90%;
  flex-wrap: wrap;
  overflow-y: auto;
  position: initial;
  margin: 25px 0 0 10px;
}