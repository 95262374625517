.thulium-chat-wrapper.thulium-desktop-chat.tc-position-right {
  /*chat icon z-index should be lower than "drawer" - 1200
   and at least as high as "appBar" - 1110*/
  z-index: 1100 !important;
}

@media only screen and (max-width: 600px) {
  .thulium-chat-frame-wrapper {
    bottom: 80px !important;
  }
}
