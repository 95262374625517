@font-face {
    font-family: "Ping 2 Bold";
    src: url("./PingL-Bold.otf") format("otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Ping 2 Regular";
    src: url("./PingL-Regular.otf") format("otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Ping 2 Medium";
    src: url("./PingL-Medium.otf") format("otf");
    font-weight: 500;
    font-style: normal;
}
